@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: black;
  height: 100vh;
}

html, body{
  min-height: 100vh;
}

@font-face {
  font-family: "MARIOKART";
  src: url("../public/fonts/SuperMario256.ttf");
}